<template>
  <div class="px-3 pt--3">
    <b-overlay :show="cargando" rounded="sm" :opacity="0.01">
      <!-- <img class="card-img" src="img/card-primary.png" alt="Card image" />-->

      <zoom-center-transition v-if="Paso=='Nuevo'" :duration="pageTransitionDuration"
                              mode="out-in">

        <RegistroComp class="mb-1" :NombreCompleto="NombreCompleto" :CorreoElectronico="CorreoElectronico" :NoTelefono="NoTelefono"
                      @guardado="OnGuardadoRegistro" @autentificacion="Paso='Autentificacion'"
                      @recuperar="Paso='RecuperarPassword'" :confirmacionLinkID="confirmacionLinkID" />
      </zoom-center-transition>
      <zoom-center-transition v-if="Paso=='Autentificacion'" :duration="pageTransitionDuration"
                              mode="out-in">
        <AutentificacionComp class="mb-1" :NombreCompleto="NombreCompleto" :CorreoElectronico="CorreoElectronico" :NoTelefono="NoTelefono"
                             :authLinkID="authLinkID"
                             @guardado="OnGuardadoAuth" @registro="Paso='Nuevo'" @recuperar="Paso='RecuperarPassword'" />
      </zoom-center-transition>
      <zoom-center-transition v-if="Paso=='RecuperarPassword'" :duration="pageTransitionDuration"
                              mode="out-in">
        <RecuperarPasswordComp class="mb-1" @guardado="OnGuardadoRecupero" />
      </zoom-center-transition>

      <div class="mx-3  d-flex justify-content-between">
        <h6 v-if="Paso!='Autentificacion'">
          <router-link class="link footer-link" :to="{}" @click.native="Paso='Autentificacion'">
            {{IniciarSesion}}
          </router-link>
        </h6>
        <!--
  <h6 v-if="Paso!='Nuevo'">
    <router-link class="link footer-link" :to="{}" @click.native="Paso='Nuevo'">
      {{CrearCuenta}}
    </router-link>
  </h6>

  <h6 v-if="Paso!='RecuperarPassword'">
    <router-link class="link footer-link" :to="{}" @click.native="Paso='RecuperarPassword'">
      {{RecuperarContrasena}}
    </router-link>

  </h6>
    -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import RegistroComp from './Registro.comp.vue';
  import AutentificacionComp from './Autentificacion.comp.vue';
  import RecuperarPasswordComp from './RecuperarPassword.comp.vue';
  import { ZoomCenterTransition } from 'vue2-transitions';
  import Usuarios from '@/api-base/usuarios.service';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import Constantes from '@/api-services/Constantes';


  export default {
    name: 'Auth-grupo-Comp',
    components: {
      RegistroComp,
      AutentificacionComp,
      RecuperarPasswordComp,
      ZoomCenterTransition,
    },
    props: {
      ///
      paso: { type: String, default: 'Nuevo' },
      //Etiqueta para recuperar la contraseña
      RecuperarContrasena: { type: String, default: 'Recuperar contraseña' },
      //Etiqueta para Crear cuenta
      CrearCuenta: { type: String, default: 'Crear cuenta' },
      IniciarSesion: { type: String, default: 'Iniciar Sesión' },

      NombreCompleto: { type: String, default: '' },
      CorreoElectronico: { type: String, default: '' },
      NoTelefono: { type: String, default: '' },

      authLinkID: { type: String, default: '' },
      confirmacionLinkID: { type: String, default: '' },
    },

    data() {
      return {
        Paso: 'Nuevo',
        pageTransitionDuration: 200,
        cargando: false,
      }
    },
    mounted() {
      if (this.confirmacionLinkID != "")
        this.Paso = "Nuevo"
      else
        if (this.authLinkID != "")
          this.Paso = "Autentificacion"
        else
          this.Paso = this.paso;
      // this.$router.replace("/");
    },
    watch: {

      paso(paso) {
        this.Paso = paso;
      },

    },
    methods: {
      CargaPerfil(OnFinalizado) {
        this.cargando = true;
         console.debug("CargaPerfil");
        Usuarios.getPerfilLocal((Datos) => {
          this.cargando = false;
          var SuscripcionID = SuscripcionesUsuarios.getSuscripcionID();
          if (SuscripcionID != null && Number.isInteger(SuscripcionID)) {
            var Suscripcion = SuscripcionesUsuarios.getSuscripcion(SuscripcionID);
            if (Suscripcion) {
              //Dejamos la suscrioción selecionada como esta
              OnFinalizado();
              return;
            }

          }

          var SU = SuscripcionesUsuarios.getSuscripcionesUsuario();
          // No tiene suscripciones, hay que ligarlo a una
          if (SU.length < 1) {

          }
          else
            if (SU.length > 1) {
              //Hay que preguntarle que con cual suscripción quiere trabajar
              //Por ahora se seleccionará la primera
              SuscripcionesUsuarios.setSuscripcionID(SU[0].suscripcionID);
            }
            else
              SuscripcionesUsuarios.setSuscripcionID(SU[0].suscripcionID);

          OnFinalizado();
        }, true);
      },
      AuthSignalR() {
        if (!Constantes.UsarSignalR)
          return false;
          try {

            if (!this.hasOwnProperty("$socket"))
              return;
            var Sesion = Usuarios.getSesion();

            this.$socket.invoke('Auth', Sesion);
          }
          finally {
            // always runs
          }

      },
      OnGuardadoRegistro() {
        var Yo = this;
        Yo.CargaPerfil(() => {
          Yo.$emit('guardado', { Estado: "Nuevo", Ruta: Yo.ObtenRuta() });
          Yo.AuthSignalR();
        });
      },
      OnGuardadoAuth() {
        var Yo = this;
        Yo.CargaPerfil(() => {
          Yo.$emit('guardado', { Estado: "Autentificacion", Ruta: Yo.ObtenRuta() });
          Yo.AuthSignalR();
        });
      },
      OnGuardadoRecupero() {
        var Yo = this;
        Yo.CargaPerfil(() => {
          Yo.$emit('guardado', { Estado: "RecuperarPassword", Ruta: Yo.ObtenRuta() });
          Yo.AuthSignalR();
        });
      },
      ObtenRuta() {
        var from = "";
        if (this.$route.query.from === undefined)
          from = '/';
        else
          from = this.$route.query.from;
        return from;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .card-titulo {
    position: relative;
    font-size: 5em;
    font-weight: 900;
    /* color: black;*/
    margin-left: -5px;
    z-index: 1;
  }
</style>
