<template>
  <card class=" px-0" headerClasses="px-0 mx-0 pt-0">

    <!--type="white"
          <div  class="text-center">
            <b-spinner type="grow" label="Loading..."></b-spinner>
          </div>
  -->
    <template v-if="estado=='capturando'" slot="header" class="pb-2">

      <h4 class="card-titulo d-block d-sm-none " style="font-size:20vw;">Registro</h4>
      <h4 class="card-titulo d-none d-sm-block ">Registro</h4>


    </template>
    <template>
      <!--
            <div  class="text-center">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
    -->
      <Main-Cargando :cargando="cargando" :MsgError="MsgError">
        <div v-if="estado=='telefono'">
          <ValidationObserver v-slot="{ handleSubmit}">
            <form @submit.prevent="handleSubmit(enviarDatos)">
              <h4 class="">Ahora validaremos con un SMS tu <b>teléfono personal</b>.</h4>
              <ValidationProvider name="TelMovilPersonal"
                                  rules=""
                                  v-slot="{ passed, failed, errors }">
                <MazPhoneNumberInput v-model="datos.telefono"
                                     :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                                     :preferred-countries="MazPhoneNumber.PaisesPreferidos"
                                     showCodeOnList @update="telefonoValido = $event" :error="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid"
                                     :loading="cargando||loading" placeholder="# Móvil Personal" />
              </ValidationProvider>

              <hr class="my-md-4" />
              <base-button v-if="!trabajando" native-type="submit" :disabled="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid" slot="footer" type="primary" block size="lg">
                Validar
              </base-button>

            </form>
          </ValidationObserver>

        </div>

        <div v-if="estado=='capturando'">
          <ValidationObserver v-slot="{ handleSubmit}">
            <form @submit.prevent="handleSubmit(enviarDatos)">
              <h4 class="mx-3 text-muted">Crea una cuenta, por seguridad los datos serán <b>verificados</b>.</h4>

              <ValidationProvider name="nombre"
                                  rules="required"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.nombre" class=" mb-2"
                          placeholder="Nombre(s)" :error="failed" autocomplete="nombre"
                          required :loading="cargando||loading" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <i slot="icon-left" class="fas fa-user"></i>
                </MazInput>
              </ValidationProvider>

              <div class="row px-2 ">
                <div class="col-md-6 mb-2 px-2">
                  <ValidationProvider name="datos.ApellidoP"
                                      rules="required"
                                      v-slot="{ passed, failed, errors }">
                    <MazInput v-model="datos.apellidoP" autocomplete="apellido-paterno"
                              placeholder="Apellido Paterno"
                              required :loading="cargando||loading" :error="failed">
                      <i slot="icon-left" class="fas fa-user-tie"></i>
                    </MazInput>
                  </ValidationProvider>
                </div>
                <div class="col-md-6  mb-2 px-2">

                  <MazInput v-model="datos.apellidoM"
                            placeholder="Apellido Materno" autocomplete="apellido-materno"
                            :loading="cargando||loading">
                    <i slot="icon-left" class="fas fa-user"></i>
                  </MazInput>
                </div>

              </div>

              <ValidationProvider name="email"
                                  rules="email|required"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.correo" class=" mb-2"
                          placeholder="Correo personal"
                          autocomplete="email" :loading="cargando"
                          type="email" :error="failed"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <i slot="icon-left" class="fas fa-envelope"></i>
                  <i slot="icon-right">

                  </i>
                </MazInput>

              </ValidationProvider>


              <base-checkbox class="text-left mt-3" v-model="terminos">
                Estoy de acuerdo con los <a href="https://www.eclock.com.mx/aviso-de-privacidad" target="_blank">terminos y condiciones</a>.
              </base-checkbox>


              <hr class="my-md-4" />
              <base-button v-if="!trabajando" native-type="submit" :disabled="!terminos" slot="footer" type="primary" block size="lg">
                Registrarse
              </base-button>

            </form>
          </ValidationObserver>



        </div>

        <div v-if="estado=='validandoCorreo'" class="text-center">


          <b-icon icon="envelope" variant="primary" font-scale="7.5"></b-icon>
          <h3 class="mb-2">{{datos.correo}}</h3>
          <h4>
            Te enviamos un correo con instrucciones para continuar. Revisa <b>correo no deseado</b>.
          </h4>
          <router-link :to="{}" @click.native="corregirCorreo=true">Cambiar correo o reenviar</router-link>

          <modal :show.sync="corregirCorreo"
                 body-classes="p-0"
                 modal-classes="modal-dialog-centered modal-sm">
            <card type="white"
                  header-classes="bg-white pb-5"
                  body-classes="px-lg-5 py-lg-5"
                  class="mb-0 text-center">
              <template>
                <h2 class="text-dark mb-4">Corrección de correo electrónico</h2>
                <ValidationObserver v-slot="{ handleSubmit}">
                  <form @submit.prevent="handleSubmit(correccionCorreo)">
                    <ValidationProvider name="email"
                                        rules="required|email"
                                        v-slot="{ passed, failed, errors }">
                      <base-input required
                                  v-model="nuevoEmail"
                                  placeholder="Correo electrónico"
                                  type="email"
                                  :error="errors[0]"
                                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                    <base-alert v-if="error" type="danger">
                      <strong>Error:</strong> {{error}}
                    </base-alert>

                    <b-spinner v-if="trabajando" type="grow" label="Loading..."></b-spinner>
                    <base-button v-if="!trabajando" type="primary" native-type="submit" class="my-4">Reenviar</base-button>

                  </form>
                </ValidationObserver>
                <router-link :to="{}" @click.native="corregirCorreo=false">Cancelar</router-link>
              </template>
            </card>
          </modal>



        </div>
        <div v-if="estado=='validandoTelefono'" class="text-center">
          <b-icon icon="phone" variant="primary" font-scale="7.5"></b-icon>


          <!-- <h1 class="text-dark ">SMS</h1>-->
          <p class="mt-3">
            Ingresa el código que te enviamos por SMS a {{datos.telefono}}. <router-link :to="{}" @click.native="corregirWhatsapp=true">Cambiar número o reenviar código</router-link>
          </p>
          <modal :show.sync="corregirWhatsapp"
                 class="modal-black" centered
                 modalContentClasses="card text-center"
                 headerClasses="card-header">

            <div slot="header">
              <h3 class=" mb-3">Corrección de número teléfonico</h3>
            </div>
            <template class="text-center">
              <ValidationObserver v-slot="{ handleSubmit}">
                <form @submit.prevent="handleSubmit(correccionWhatsapp)">
                  <ValidationProvider name="TelMovilPersonal"
                                      rules=""
                                      v-slot="{ passed, failed, errors }">
                    <MazPhoneNumberInput v-model="nuevoWhatsapp"
                                         :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                                         :preferred-countries="MazPhoneNumber.PaisesPreferidos"
                                         showCodeOnList @update="nuevoWhatsappValido = $event" :error="nuevoWhatsappValido!=null && nuevoWhatsapp != null && nuevoWhatsapp != '' && !nuevoWhatsappValido.isValid"
                                         :loading="cargando||loading" placeholder="# Móvil Personal" />
                  </ValidationProvider>
                  <base-alert v-if="error" type="danger">
                    <strong>Error:</strong> {{error}}
                  </base-alert>

                  <b-spinner v-if="trabajando" type="grow" label="Loading..."></b-spinner>
                  <p v-if="segundosPendientes>0">Espere {{segundosPendientes}} segundos</p>
                  <base-button v-if="!trabajando && segundosPendientes<=0" :disabled="nuevoWhatsappValido!=null && nuevoWhatsapp != null && nuevoWhatsapp != '' && !nuevoWhatsappValido.isValid" type="primary" native-type="submit" class="my-4">Enviar nuevo código</base-button>
                </form>

              </ValidationObserver>
              <router-link :to="{}" @click.native="corregirWhatsapp=false">Cancelar</router-link>


            </template>
          </modal>
          <div class="mt-3 mb-2 d-flex justify-content-center">
            <CodeInput :loading="false" v-on:complete="CodigoCompletado" :fields="5" />
          </div>
          <!--
                  <ValidationObserver v-slot="{ handleSubmit}">
                    <form @submit.prevent="handleSubmit(validarTelefono)">
                      <ValidationProvider name="codigoWhatsapp"
                                          rules="required|min:5"
                                          v-slot="{ passed, failed, errors }">
                        <base-input required
                                    v-model="codigoWhatsapp"
                                    placeholder="Ingresa el código"
                                    addon-left-icon="tim-icons icon-lock-circle"
                                    type="phone"
                                    :error="errors[0]"
                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                      <base-button v-if="!trabajando" native-type="submit" slot="footer" type="primary" block size="lg">
                        Validar
                      </base-button>
                    </form>
                  </ValidationObserver>
        -->

        </div>


        <div class="text-center">
          <div v-if="estado=='usuarioPreviamenteRegistrado' || estado=='registroErroneo'" class="mb-3">
            <i class="fas fa-times-circle fa-7x"></i>
          </div>
          <div v-if="estado=='registroErroneo'">
            <p>No se pudo registrar, intente nuevamente o contacte a soporte técnico.</p>
            <router-link :to="{  } " @click.native="confirmarRegistro">
              Reintentar registro
            </router-link>
          </div>
        </div>

        <div v-if="trabajando" class="text-center">
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>



        <div v-if="error" class="text-center">
          <base-alert type="danger">
            <strong>Error:</strong> {{error}}

          </base-alert>
          <router-link :to="{  } " @click.native="CapturarNuevamente" class="mx-3">
            Capturar nuevamente
          </router-link>
        </div>
      </Main-Cargando>

    </template>
  </card>
</template>
<script>

  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";
  import Usuarios from '@/api-base/usuarios.service';
  import Constantes from '@/api-services/Constantes';
  import Base from '@/api-base/base';
  import { localize } from "vee-validate";
  import CodeInput from "vue-verification-code-input";

  //  import {  MazPhoneNumberInput } from 'maz-ui'
  //const MazPhoneNumberInput = () => import( /* webpackChunkName: "main" */ 'maz-ui');

  extend("email", email);
  //extend("phone", phone);
  extend("required", required);
  extend("min", min);
  localize({
    es: {
      messages: {
        // generic rule messages...
      },
      fields: {
        codigoCorreo: {

          required: 'Requerimos el código enviado a tu correo electrónico',

          min: 'Debe ser mínimo de {length} caracteres'
        },
        codigoWhatsapp: {

          required: 'Requerimos el código enviado a tu teléfono',
          min: 'Debe ser mínimo de {length} caracteres'

        },
      }
    }
  });


  export default {
    name: 'Registro-Comp',
    components: {
      //  MazPhoneNumberInput
      CodeInput
    },
    data() {
      return {

        datos: {
          nombre: '',
          apellidoP: '',
          apellidoM: '',
          correo: '',
          correoCodigo: '',
          telefono: '',
          telefonoCodigo: '',

        },
        cargando: false,
        loading: false,
        MsgError: '',

        nuevoWhatsappValido:'',
        telefonoValido: '',
        nuevoEmail: '',
        whatsapp: '',
        nuevoWhatsapp: '',
        // whatsappFinal:'',
        terminos: false,
        codigoCorreo: '',
        codigoWhatsapp: '',

        capturando: true,
        validandoCorreo: false,
        validandoTelefono: false,

        corregirCorreo: false,
        corregirWhatsapp: false,

        error: '',
        trabajando: false,
        estado: 'capturando',

        ultimoSMS: (new Date()).getTime(),
        segundosReintento: 60,
        segundosPasaron: 0,

      };
    },
    props: {


      NombreCompleto: { type: String, default: '' },
      CorreoElectronico: { type: String, default: '' },
      NoTelefono: { type: String, default: '' },

      confirmacionLinkID: { type: String, default: '' },

    },
    watch: {
      CorreoElectronico(Valor) {
        this.datos.correo = Valor;
      },
      NombreCompleto(Valor) {
        this.datos.nombre = Valor;
      },
      NoTelefono(Valor) {
        this.datos.telefono = Valor;
      },
    },
    computed: {
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
      sufijo() {
        return Math.random();
      },
      autocompleteNombre() {
        return `${this.$options.name}-Nombre-${sufijo}`;
      },
      autocompleteWhatsApp() {
        return `${this.$options.name}-WhatsApp-${sufijo}`;
      },
      autocompleteCorreo() {
        return `${this.$options.name}-Correo-${sufijo}`;
      },
      autocompleteClave() {
        return `${this.$options.name}-Clave-${sufijo}`;
      },
      segundosPendientes() {
        if (this.segundosPasaron > this.segundosReintento)
          return 0;
        return this.segundosReintento - this.segundosPasaron;


      }

    },
    mounted() {
      if (this.confirmacionLinkID != undefined && this.confirmacionLinkID != null && this.confirmacionLinkID != "")
        Usuarios.GetReferencia(this, this.confirmacionLinkID, (response) => {
          this.datos = response.data;
          this.datos.correoCodigo = this.confirmacionLinkID,
            this.estado = 'telefono';
            //this.estado = "validandoTelefono";
        });

      this.startTimer();
      this.datos.correo = this.CorreoElectronico;
      this.datos.nombre = this.NombreCompleto;
      this.datos.telefono = this.NoTelefono;

    },
    methods: {
      CodigoCompletado(V) {
        //console.debug(V);
        this.datos.telefonoCodigo = V;
        return this.enviarDatos();
      },
      CapturarNuevamente() {
        this.estado = 'capturando';
        this.error = '';
        this.codigoCorreo = '';
        this.codigoWhatsapp = '';
      },
      startTimer() {
        this.timerInterval = setInterval(() => (
          this.segundosPasaron += 1), 1000);
      },
      submit() {
        alert("Form has been submitted!");
      },

      enviarDatos() {
        if (this.cargando)
          return;

        this.cargando = true;
        this.MsgError = "";
        this.datos.BaseURL = Constantes.URLConfirmacion;
        Usuarios.postUsuario(this.datos).then((response) => {
          //Debio regresar la sesión
          if (response.status == 200) {
            this.trabajando = false;
            this.estado = "registroSatisfactorio";
            localStorage.sesion = response.data;
            console.debug(response.data);
            this.$emit('guardado', response.data);
            return;
          }
          
          this.corregirCorreo = false;
          if (this.estado == 'telefono') {
            this.estado = 'validandoTelefono';
            this.nuevoWhatsapp = this.datos.telefono;
            this.segundosReintento = this.segundosPasaron + this.segundosReintento;
          }
          else
            this.estado = "validandoCorreo";



        }).catch((error) => {


          var MsgError = Base.ObtenErrorMsg(error);

          if (error.response != undefined) {
            switch (error.response.status) {
              case 409:
                MsgError = "Ya se encuentra registrado";
                break;
              case 401:
                MsgError = "Código inválido, intente nuevamente";
                break;
            }
          }
          this.MsgError = MsgError;
          /*
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }*/
        }
        ).finally(() => {
          this.cargando = false;

        });
      },
      correccionCorreo() {
        if (this.cargando)
          return;
        this.datos.correo = this.nuevoEmail;
        this.enviarDatos();
      },
      correccionWhatsapp() {
          if (this.cargando)
            return;
        this.datos.telefono = this.nuevoWhatsapp;
        this.enviarDatos();
      },

      confirmarRegistro() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.postUsuario({ nombre: this.fullname, correo: this.email, telefono: this.whatsapp, password: this.password }).then((response) => {

          this.trabajando = false;
          this.estado = "registroSatisfactorio";
          localStorage.sesion = response.data;

          this.$emit('guardado', response.data);
          //localStorage.usuario = JSON.stringify(response.data.resultado.usuario);


        }).catch((error) => {
          this.trabajando = false;

          this.estado = "registroErroneo";
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {
            this.error = "Intenta nuevamente o contacte a soporte técnico";
          }
          else {

            console.error(error.response.data.error);
            if (error.response.status == 409) {
              this.error = `${error.response.data.error.mensaje}, intente recuperar la contraseña`;
              this.estado = "usuarioPreviamenteRegistrado";
            }
            else
              this.error = error.response.data.error.mensaje;
          }
        }
        );
      }
    }
  };
</script>
<style lang="scss" scoped>
  .card-titulo {
    position: relative;
    font-size: 5em;
    font-weight: 900;
    /* color: black;*/
    margin-left: -5px;
    z-index: 1;
  }
</style>

