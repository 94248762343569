<template>
    <card class=" px-0" headerClasses="px-0 mx-0 pt-0">

      <!--
              <div  class="text-center">
                <b-spinner type="grow" label="Loading..."></b-spinner>
              </div>
      -->
      <template v-if="estado==''" slot="header" class="pb-0 mb-0">
        <!-- <img class="card-img" src="img/card-primary.png" alt="Card image" />-->

        <h4 class="card-titulo d-block d-sm-none " style="font-size:18vw;">Inicio de sesión</h4>
        <h4 class="card-titulo d-none d-sm-block ">Inicio de sesión</h4>


      </template>
      <template>
        <Main-Cargando :cargando="cargando" :MsgError="MsgError">
          <!--    <div v-if="estado=='validandoCorreo'" class="text-center">

    <b-icon icon="envelope" variant="primary" font-scale="7.5"></b-icon>
    <h3 class="mb-2">{{datos.correo}}</h3>
    <h4>
      Te enviamos un correo con instrucciones para continuar. Revisa <b>correo no deseado</b>.
    </h4>
    <router-link :to="{}" @click.native="estado=''">Cambiar correo o reenviar</router-link>
  </div>-->


          <div v-if="estado=='ValidarTelefono'" class="text-center">
            <b-icon icon="phone" variant="primary" font-scale="7.5"></b-icon>
            <h3 class="mb-2">{{datos.telefono}}</h3>
            <h4>
              Ingresa le código que te acabamos de enviar por <b>SMS</b>.
</h4>
            <div class="mt-3 mb-2 d-flex justify-content-center">
              <CodeInput :loading="false" v-on:complete="CodigoCompletado" :fields="5" />
            </div>
            <!--    <router-link v-if="datos.registrando" :to="{}" @click.native="estado=''"><i class="fas fa-chevron-left"> </i> Cambiar correo o reenviar</router-link>-->
            <div class="mx-3  d-flex justify-content-between">
              <router-link class="link footer-link" :to="{}"  @click.native="EnviarSms">Reenviar</router-link>
              <router-link class="link footer-link" :to="{}"  @click.native="EnviarEmail">Enviar correo</router-link>
            </div>
          </div>

          <div v-if="estado=='ValidarCorreo'" class="text-center">


            <b-icon icon="envelope" variant="primary" font-scale="7.5"></b-icon>
            <h3 class="mb-2">{{datos.correo}}</h3>
            <h4>
              Ingresa le código que te acabamos de enviar por correo. Revisa <b>correo no deseado</b>.
            </h4>
            <div class="mt-3 mb-2 d-flex justify-content-center">
              <CodeInput :loading="false" v-on:complete="CodigoCompletado" :fields="5" />
            </div>
            <!--    <router-link v-if="datos.registrando" :to="{}" @click.native="estado=''"><i class="fas fa-chevron-left"> </i> Cambiar correo o reenviar</router-link>-->
            <div class="mx-3  d-flex justify-content-between">
              <router-link class="link footer-link" :to="{}" @click.native="EnviarEmail">Reenviar</router-link>
              <router-link class="link footer-link" :to="{}" @click.native="EnviarSms">Enviar SMS</router-link>
            </div>
          </div>


          <ValidationObserver v-if="estado==''" v-slot="{ handleSubmit }">
            <h4 class="mx-3 text-muted">Utiliza tus datos previamente registrados.</h4>

            <form @submit.prevent="handleSubmit(submit)" class="mt-3">
              <ValidationProvider name="email"
                                  rules="email|required"
                                  v-slot="{ passed, failed, errors }">
                <MazInput v-model="datos.correo" class=" mb-2"
                          placeholder="Correo"
                          :autocomplete="autocompleteCorreo" :loading="cargando"
                          type="email" :error="failed"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <i slot="icon-left" class="fas fa-envelope"></i>
                  <i slot="icon-right">
                  </i>
                </MazInput>
              </ValidationProvider>
              <ValidationProvider name="TelMovilPersonal"
                                  rules=""
                                  v-slot="{ passed, failed, errors }">
                <MazPhoneNumberInput v-model="datos.telefono"
                                     :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                                     :preferred-countries="MazPhoneNumber.PaisesPreferidos"
                                     showCodeOnList @update="telefonoValido = $event" :error="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid"
                                     :loading="cargando||loading" placeholder="# Teléfono" />
              </ValidationProvider>

              <hr class="my-md-4" />
              <base-button v-if="!trabajando" native-type="submit" :disabled="telefonoValido!=null && datos.telefono != null && datos.telefono != '' && !telefonoValido.isValid" slot="footer" type="primary" block size="lg">
                Iniciar sesión
              </base-button>

            </form>



          </ValidationObserver>
        </Main-Cargando>
      </template>
    </card>

</template>
<script>

  import { extend } from "vee-validate";
  // import { BaseAlert } from 'src/components';

  import Constantes from '@/api-services/Constantes';
  import Usuarios from '@/api-base/usuarios.service';
  import Base from '@/api-base/base';
  import { required, email, min } from "vee-validate/dist/rules";
  import CodeInput from "vue-verification-code-input";
  extend("email", email);
  extend("min", min);

  extend("required", required);
  /* // Install English and Arabic locales.
   localize(
     'es'
   );

   localize({
     es: {
       messages: {
         // generic rule messages...
       },
       fields: {
         fullname: {

           required: 'Se requiere nombre completo',
         },

         email: {
           required: 'Se requiere un correo electrónico válido',
           email: 'Se requiere un correo electrónico válido',
         },
         password: {
           required: 'Se requiere contraseña',
           min: 'La contraseña debe ser mínimo de {length} caracteres'
         }
       }
     }
   });*/
  import swal from 'sweetalert2';

  export default {
    props: {
      CorreoElectronico: { type: String, default: '' },
      authLinkID: { type: String, default: '' },
    },
    data() {
      return {
        datos: {
          correo: "",
          telefono: "",
          SMS: false,
          correoCodigo: '',
          telefonoCodigo: '',
        },
        telefonoValido: "",
        passwordLen: 5,
        email: "",
        password: "",
        error: "",
        trabajando: false,
        cargando: false,
        loading: false,
        MsgError: "",
        estado: ""
      };
    },
    watch: {
      CorreoElectronico(Valor) {
        this.email = Valor;
      },

    },
    components: {
      CodeInput
    },
    //https://es.vuejs.org/v2/guide/instance.html
    created: function () {
      // `this` hace referencia a la instancia vm

    },
    mounted() {
      this.email = this.CorreoElectronico;
      if (this.authLinkID != "") {
        this.datos.ReferenciaLinkID = this.authLinkID;
        this.submit();
      }
    },
    methods: {
      LimpiarCodigos() {
        this.datos.correoCodigo = "";
        this.datos.telefonoCodigo = "";
      },
      CodigoCompletado(Codigo) {
        this.LimpiarCodigos();
        if (this.datos.SMS)
          this.datos.telefonoCodigo = Codigo;
        else
          this.datos.correoCodigo = Codigo;

        this.submit();
      },
      getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      },
      EnviarSms() {
        this.datos.telefonoCodigo = "";
        this.datos.SMS = true;
        this.submit();

      },
      EnviarEmail() {
        this.datos.correoCodigo = "";
        this.datos.SMS = false;
        this.submit();

      },
      submit() {
        if (this.trabajando || this.cargando)
          return;
        this.trabajando = true;
        this.cargando = true;
        this.MsgError = this.error = "";
        //   this.datos.BaseURL = Constantes.URLAutentificacion;
        //  this.datos.SMS = false;
        Usuarios.postSesion(this.datos).then((response) => {
          this.LimpiarCodigos();
          switch (response.data.estadoSesion) {
            case "Autentificado":
              localStorage.sesion = response.data.sesion;
              localStorage.perfilLocal = JSON.stringify(response.data.perfil);
              this.$emit('guardado', response.data);
              break;
            default:
              this.estado = response.data.estadoSesion;
              break;
          }


        }).catch((error) => {

          ///Usuario o contraseña inválido
          if (error.response != null && error.response.status == 401) {

            if (error.response.data == "Codigo")
              this.error = "Código inválido, verifica o solicita un código nuevo";
            else
              this.error = "El correo y el teléfono no coinciden";

          }
          else
            if (error.response != null && error.response.status == 404) {

              this.error = "El código ya fue utilizado, intente nuevamente";

            }
            else
              if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

                console.error(error);
                this.error = "Intente nuevamente";
              }
              else {

                console.error(error.response.data.error);
                this.error = error.response.data.error.mensaje;
              }
          this.MsgError = this.error;
        }
        ).finally(() => {
          this.trabajando = false;
          this.cargando = false;
          this.datos.ReferenciaLinkID = '';
        });;
      }
    },
    computed: {

      autocompleteCorreo() {
        return `Correo${this.getRandomInt(1, 100000)}`;
      },
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },
      passwordValidacion() {
        return `required|min:${this.passwordLen}`;

      }

    },
  };
</script>
<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
</style>
<style lang="scss" scoped>
  .card-titulo {
    position: relative;
    font-size: 5em;
    font-weight: 900;
    /* color: black;*/
    margin-left: -5px;
    z-index: 1;
  }
</style>


