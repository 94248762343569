import Base from '@/api-base/base'

import Axios from 'axios';
import LiAuth from '@/api-base/liauth.service'
const servicio = `${Base.URL}/suscripciones`;
import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
const SUSCRIPCIONES_LOGO = `/img/brand/Logo512.png`;

export default {
  ePlan: [
    "Gratuito",
    "Bronce",
    "Plata",
    "Oro",
    "Platino",
    "Diamante",
    "DiamanteElite"
  ],

  EtiquetaByID(SuscripcionID) {
    var Suscripcion = SuscripcionesUsuarios.getSuscripcion(SuscripcionID);
    if (Suscripcion == null)
      return "No encontrado";

    return Suscripcion.etiqueta;

  },
  LogoByID(SuscripcionID) {
    var Suscripcion = SuscripcionesUsuarios.getSuscripcion(SuscripcionID);
    if (Suscripcion == null)
      return SUSCRIPCIONES_LOGO;
    return this.Logo(Suscripcion);

  },
  Logo(Suscripcion) {
    if (Suscripcion == null || Suscripcion.urlLogoMiniatura == null)
      return SUSCRIPCIONES_LOGO;
    return Suscripcion.urlLogoMiniatura;
  },

  postSuscripcion(Datos) {
    return Axios.post(servicio, Datos, LiAuth.header());
  },
  /*
      getAccesos(Desde, Hasta) {
          var Ruta = `${ACCESOS}?Desde=${Desde.toISOString()}&Hasta=${Hasta.toISOString()}`;
          return Axios.get(Ruta, LiAuth.header());
      },
      */


  GetsResponse: null,
  Listado: [],
  FotoURL(Registro) {
    return "/img/grupos.svg";
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.get(Vista, servicio, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.get(Vista, servicio, { PaginaLen: 1000 }, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = `${servicio}/${ID}`;
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.post(Vista, servicio, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.get(Vista, `${servicio}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },

  postComentarios(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var ID = SuscripcionesUsuarios.getSuscripcionID();
    var URL = `${servicio}/${ID}/Comentarios`;
    return Base.post(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
}
