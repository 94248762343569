<template>
    <card class=" px-0" headerClasses="px-0 mx-0 pt-0">

      <!--
              <div  class="text-center">
                <b-spinner type="grow" label="Loading..."></b-spinner>
              </div>
      -->
      <template slot="header" class="pb-2">
        <!-- <img class="card-img" src="img/card-primary.png" alt="Card image" />-->
        <h4 class="card-titulo d-block d-sm-none " style="font-size:15vw;">Recupera tu clave</h4>
        <h4 class="card-titulo d-none d-sm-block " >Recupera tu clave</h4>
        <h4 class="mx-3 text-muted">Regenera tu inicio de sesión utilizando tu correo electrónico.</h4>

      </template>
      <template>
        <ValidationObserver v-slot="{ handleSubmit }">



          <div class="text-center">
            <!--Por ahora solo se podrá iniciar con credenciales locales-->
            <!--
            <div class="text-muted text-center "><small>Iniciar con:</small></div>
            <div class="btn-wrapper text-center">
              <button class="btn btn-icon btn-facebook">
                <i class="fab fa-facebook-f"> </i>
              </button>
              <button class="btn btn-icon btn-google">
                <i class="fab fa-google"></i>
              </button>
              <button class="btn btn-icon btn-linkedin">
                <i class="fab fa-microsoft"></i>
              </button>
            </div>
            <div class="text-center text-muted mt-2">
              <small>O con tus credenciales</small>
            </div>
              -->
            <div v-if="estado=='datos'">
              <b-icon icon="shield-lock" variant="primary" font-scale="7.5"></b-icon>
              <form @submit.prevent="handleSubmit(submit)" class="mt-3">

                <ValidationProvider name="email"
                                    rules="required|email"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="email"
                              type="email"
                              placeholder="Correo electrónico"
                              addon-left-icon="tim-icons icon-email-85"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <div v-if="trabajando" class="text-center">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
                <base-button v-else native-type="submit" type="primary" class="mb-3" size="lg" block>
                  Solicitar clave de recuperación
                </base-button>
              </form>
            </div>
            <div v-if="estado=='validacion'">
              <b-icon icon="envelope" variant="primary" font-scale="7.5"></b-icon>
              <form @submit.prevent="handleSubmit(validacion)" class="mt-3">
                <p class=" mt-3">
                  Se te ha enviado un correo a {{email}} con tu código de verificación, revisa correo no deseado. <a href="#" @click="estado=='datos'">Cambiar correo</a>
                </p>
                <ValidationProvider name="codigo"
                                    rules="required|min:5"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="codigo"
                              placeholder="Ingresa el código"
                              addon-left-icon="tim-icons icon-lock-circle"
                              type="phone"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>


                <div v-if="trabajando" class="text-center">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
                <base-button v-else native-type="submit" type="primary" class="mb-3" size="lg" block>
                  Validar
                </base-button>
              </form>
            </div>

            <div v-if="estado=='nuevoPassword'">
              <b-icon icon="person-check-fill" variant="primary" font-scale="7.5"></b-icon>
              <form @submit.prevent="handleSubmit(nuevoPassword)" class="mt-3">
                <p class=" mt-3">
                  Ingresa tu nueva contraseña.
                </p>
                <ValidationProvider name="password"
                                    rules="required|min:5"
                                    v-slot="{ passed, failed, errors }">
                  <base-input required
                              v-model="password"
                              placeholder="Contraseña"
                              addon-left-icon="tim-icons icon-lock-circle"
                              type="password"
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>


                <div v-if="trabajando" class="text-center">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
                <base-button v-else native-type="submit" type="primary" class="mb-3" size="lg" block>
                  Validar
                </base-button>
              </form>
            </div>

            <base-alert v-if="error" type="danger">
              <strong>Error:</strong> {{error}}
            </base-alert>
          </div>




        </ValidationObserver>
      </template>
    </card>
</template>
<script>

  import Usuarios from '@/api-base/usuarios.service';
  import { extend, localize } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";

  extend("email", email);
  extend("min", min);

  extend("required", required);
  // Install English and Arabic locales.
  localize(
    'es'
  );

  localize({
    es: {
      messages: {
        // generic rule messages...
      },
      fields: {
        email: {
          required: 'Se requiere un correo electrónico válido',
          email: 'Se requiere un correo electrónico válido',
        },
        codigo: {
          required: 'Requerimos el código que te enviamos',
          min: 'Debe ser mínimo de {length} caracteres'
        }
      }
    }
  });

  export default {
    data() {
      return {
        passwordLen: 5,
        email: "",
        codigo: "",
        error: "",
        password: "",
        trabajando: false,
        estado: "datos"
      };
    },

    methods: {
      submit() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.GetRecuperarPassword(this.email).then((response) => {
          this.trabajando = false;
          this.estado = "validacion";
        }).catch((error) => {
          this.trabajando = false;
          ///Usuario o contraseña inválido
          if (error.response != undefined && error.response.status == 400) {

            this.error = "Usuario no registrado";
            return;
          }
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },

      validacion() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.postSesion({ correoTelefono: this.email, password: this.codigo }).then((response) => {
          this.trabajando = false;
          //guarda la sesión
          localStorage.sesion = response.data;
          this.estado = "nuevoPassword";
        }).catch((error) => {
          this.trabajando = false;
          ///Usuario o contraseña inválido
          if (error.response != undefined && error.response.status == 401) {
            console.debug(error.response.status);
            this.error = "Código inválido, intente nuevamente o solicite soporte técnico";
            return;
          }


          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );
      },
      nuevoPassword() {
        if (this.trabajando)
          return;
        this.trabajando = true;
        this.error = "";
        Usuarios.put({ passwordAnterior: this.codigo, passwordNuevo: this.password }).then((response) => {
          this.trabajando = false;
          //localStorage.usuario = JSON.stringify(response.data.resultado.usuario);
         // localStorage.sesion = response.data;

          this.$emit('guardado', response.data);
        }).catch((error) => {
          this.trabajando = false;


          ///Usuario o contraseña inválido
          if (error.response != undefined && error.response.status == 401) {
            console.debug(error.response.status);
            this.error = "La sesión dejo de ser válida, repita el proceso completamente";
            return;
          }

          
          if (error.response === undefined || error.response.data === undefined || error.response.data.error === undefined) {

            console.error(error);
            this.error = "Intente nuevamente";
          }
          else {

            console.error(error.response.data.error);
            this.error = error.response.data.error.mensaje;
          }
        }
        );

      }
    },
    computed: {


    },
  };
</script>
<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
</style>
<style lang="scss" scoped>
  .card-titulo {
    position: relative;
    font-size: 5em;
    font-weight: 900;
    /* color: black;*/
    margin-left: -5px;
    z-index: 1;
  }
</style>

